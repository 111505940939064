// Modules //
import React from 'react';
import {Container, Col, Row, Card} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './StrategyAndBusinessDevelopment.css';
// Assets //
import Submarine_Network06 from "../../assets/submarine_network_06.jpg"

function StrategyAndBusinessDevelopment() {
  return (
   <>
    <br />
    <br />
    <Container id="mission_statement_page">
    <Row>
      <Col>
        <br />
        <FadeIn delay="150">
        <Card bg="transparent" text="less-light">
          <Card.Body>
            <Card.Text>
              <div id="sabd_text">
              <p>Taho!’s extensive network of partners has connections with both regional and global service providers & manufacturers.</p>
              <p>This allows us to provide you with the adequate support in any area (technical, commercial or financial), for any kind of technology, regardless of whether your needs are for entering a new market, or develop or extending existing businesses.</p>
              <p>We can provide any support you might need, ranging from risks analysis, planning & project management, costs control, implementation management, etc…</p>
              <p>We can provide the necessary skills to assist you in overall management, opportunity studies for new services/products, specifications & RFQs, suppliers selection, organization and operation of your networks</p>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        </FadeIn>
      </Col>
      <Col>
      <br />
      <br />
      <br />
        <FadeIn delay="350">
          <Card>
            <Card.Img variant="top" src={Submarine_Network06} />
          </Card>
          <blockquote className="Miguel_Chevalier_Quote">by <b><i><a target="_blank" rel="noopener noreferrer" href="https://www.miguel-chevalier.com/">Miguel Chevalier</a></i></b></blockquote>
        </FadeIn>
      </Col>
      </Row>
    </Container>
   </>
  );
}

export default StrategyAndBusinessDevelopment;
