// Modules //
import React from 'react';
import {Container, Col, Row} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './My404.css';

// Assets //
import ma404 from "../../assets/404.png"
function My404() {
  return (
   <>
    <Container id="My404_page">
      <Row>
        <Col></Col>
        <Col xs={6}>
          <FadeIn delay="150">
          <img src={ma404} width="400" height="400"  alt="404" />
          <p><h1 style={{color: "white"}}>PAGE NOT FOUND</h1></p>
          </FadeIn>
        </Col>
        <Col></Col>
      </Row>
    </Container>
   </>
  );
}

export default My404;
