// Modules //
import React from 'react';
import {Container, Col, Row, Card} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './Contacts.css';
// Assets //

function Contacts() {
  return (
   <>
   <br />
    <Container id="mission_statement_page">
      <Row>
        <Col></Col>
        <Col>
          <FadeIn delay="350">
            <Card bg="transparent" text="less-light" id="CONTACT">
              <Card.Body>
                <Card.Text>
                  <p><h2>Email</h2></p>
                  <hr className="whiteBorder" />
                  <p><h4>contact(at)taho(dot)fr</h4></p>
                </Card.Text>
              </Card.Body>
            </Card>
          </FadeIn>
        </Col>
        <Col></Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col></Col>
        <Col>
          <FadeIn delay="300">
            <Card bg="transparent" text="less-light" id="CONTACT">
              <Card.Body>
                <Card.Text>
                  <p><h1>Legal Mentions</h1></p>
                  <hr className="whiteBorder" />
                </Card.Text>
              </Card.Body>
            </Card>
          </FadeIn>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <FadeIn delay="350">
            <Card bg="transparent" text="less-light" id="CONTACT">
              <Card.Body>
                <Card.Text>
                <p><h2>Company</h2></p>
                  <hr className="whiteBorder" />
                  <p>Company: 	Taho! SAS</p>
                  <p>Capital: 	8000 €</p>
                  <p>Registration No: 442 490 959 RCS Paris </p>
                  <p>Address: 	6 rue Erlanger 75016 Paris, France </p>
                  <p>Tel: 	+33 (0) 1 53 84 51 30 </p>
                  <p>Email address: 	contact(at)taho(dot)fr</p>
                  <p>Publishing Director: 	Gérard Dupin</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </FadeIn>
        </Col>
        <Col>
          <FadeIn delay="350">
            <Card bg="transparent" text="less-light" id="CONTACT">
              <Card.Body>
                <Card.Text>
                  <p><h2>Host Site</h2></p>
                  <hr className="whiteBorder" />
                  <p>Host server: 	server: 1&1 IONOS</p>
                  <p>Host server address: 	7, place de la Gare, BP 70109, 57201 Sarreguemines Cedex, France</p>
                  <p>Site made by : <b>Corentin Laborie</b></p> 
                </Card.Text>
              </Card.Body>
            </Card>
          </FadeIn>
        </Col>
      </Row>
    </Container>
   </>
  );
}

export default Contacts;
