// Modules //
import React from 'react';
import {Navbar, Nav} from "react-bootstrap";
// Stylesheets // 
import './MyNavbar.css';

// Assets //

import TahoLogo from "../../assets/logo.jpg"

function MyNavbar() {
  return (
   <>
   <Navbar bg="light" expand="lg" sticky="top" id="myNavbar"> 
    <Navbar.Brand href="/">
      <img
        src={TahoLogo}
        width="90"
        height="80"
        className="d-inline-block align-top"
        alt="Taho! logo"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav variant="tabs" className="mr-auto">
      <Nav.Link href="/about_us">About us</Nav.Link>
      <Nav.Link href="/mission_statement">Mission Statement</Nav.Link>
      <Nav.Link href="/subsea_networks">Subsea Networks</Nav.Link>
      <Nav.Link href="/strategy_and_business_development">Strategy & Business Development</Nav.Link>
      <Nav.Link href="/art">Art</Nav.Link>
      <Nav.Link href="/principles">Principles</Nav.Link>
      <Nav.Link href="/contacts">Contacts</Nav.Link>
    </Nav>
    </Navbar.Collapse>
  </Navbar>
   </>
  );
}

export default MyNavbar;
