// Modules //
import React from 'react';
import {Container, Col, Row, Card} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './AboutUs.css';

// Assets //
import Submarine_Network02 from "../../assets/submarine_network_02.jpg"

function AboutUs() {
  return (
   <>
    <br />
    <br />
    <Container id="about_us_page">
      <Row>
        <Col>
        <br />
        <br />
          <FadeIn delay="150">
          <Card bg="transparent" text="less-light">
            <Card.Body>
              <Card.Text>
                <div id="about_us_text">
                  <p><ins>Taho!</ins> was created at the beginning of 2002 to provide consultancy services in the area of telecommunications.</p>
                  <p>With a strong experience in the telecom area, including a significant background in subsea networks, and an extensive network of partners, Taho! has a global reach, covering all aspects of the telecom industry.</p>
                  <p>Through our connections with service providers and manufacturers, we can provide your company with the adequate support in any area, for entering a new market, developing or extending existing business.</p>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          </FadeIn>
        </Col>
        <Col>
        <br />
        <br />
        <br />
          <FadeIn delay="350">
            <Card>
              <Card.Img variant="top" height="359" width="359" src={Submarine_Network02} />
            </Card>
            <blockquote className="Miguel_Chevalier_Quote">by <b><i><a target="_blank" rel="noopener noreferrer" href="https://www.miguel-chevalier.com/">Miguel Chevalier</a></i></b></blockquote>
          </FadeIn>
        </Col>
      </Row>
    </Container>
   </>
  );
}

export default AboutUs;
