// Modules //
import React from 'react';
import {Container, Col, Row, Card} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './Welcome.css';

// Assets //
import Submarine_Network01 from "../../assets/submarine_network_01&03.jpg"
function Welcome() {
  return (
   <>
    <Container fluid id="welcome_page">
      <Row>
        <Col>
          <br />
          <h1 id="title_welcome">We help leading companies meet their business objectives</h1>
        </Col>
      </Row>
      <br />
      <Row>
        <Col></Col>
        <Col xs={6}>
          <FadeIn delay="150">
            <Card>
              <Card.Img variant="top" src={Submarine_Network01} />
            </Card>
          {/* <img src={Submarine_Network01} width="auto" height="auto" id="img_welcome" alt="submarine_network01.jpg" /> */}
            <blockquote className="Miguel_Chevalier_Quote">by <b><i><a target="_blank" rel="noopener noreferrer" href="https://www.miguel-chevalier.com/">Miguel Chevalier</a></i></b></blockquote>
          </FadeIn>
        </Col>
        <Col></Col>
      </Row>
    </Container>
   </>
  );
}

export default Welcome;
