// Modules //
import React from 'react';
import {Container, Col, Row, Card} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './SubseaNetworks.css';
// Assets //
import Submarine_Network04 from "../../assets/submarine_network_04.jpg"

function SubseaNetworks() {
  return (
   <>
      <br />
    <br />
    <Container id="mission_statement_page">
    <Row>
      <Col>
        <br />
        <br />
        <br />
        <br />
        <FadeIn delay="150">
        <Card bg="transparent" text="less-light">
          <Card.Body>
            <Card.Text>
              <div id="subsea_networks_text">
                <p><ins>Taho!</ins> provides support to service providers or manufacturers for all aspects of submarine networks, from preliminary studies & business development up to network realization and long term operation & maintenance.</p>
                <p>The team has an in-depth knowledge of the submarine systems business and of the overall telecom market, longstanding relationships with manufacturers and service providers, and in particular with alternative operators.</p>
                <p>We can therefore help you to successfully realize your projects in the <ins><i>MOST OPTIMIZED WAY.</i></ins></p>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        </FadeIn>
      </Col>
      <Col>
      <br />
      <br />
      <br />
        <FadeIn delay="350">
          <Card>
            <Card.Img variant="top" height="459" width="359" src={Submarine_Network04} />
          </Card>
          <blockquote className="Miguel_Chevalier_Quote">by <b><i><a target="_blank" rel="noopener noreferrer" href="https://www.miguel-chevalier.com/">Miguel Chevalier</a></i></b></blockquote>
        </FadeIn>
      </Col>
      </Row>
    </Container>
   </>
  );
}

export default SubseaNetworks;
