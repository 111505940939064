// Modules //
import React from 'react';
import {Container, Col, Row, Card} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './Principles.css';
// Assets //
import GerardDupin from "../../assets/gerard_dupin.jpg"

function Principles() {
  return (
   <>
    <Container id="principles_page">
      <Row>
        <Col></Col>
        <Col>
        <br />
        <FadeIn delay="150">
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top"  src={GerardDupin} />
          </Card>
          <blockquote className="Miguel_Chevalier_Quote">by <b><i><a target="_blank" rel="noopener noreferrer" href="http://yveslevy.mystrikingly.com/">Yves Levy</a></i></b></blockquote>
        </FadeIn>
        </Col>
        <Col></Col>
      </Row>
    <Row>
      <Col>
        <FadeIn delay="450">
        <Card bg="transparent" text="less-light">
          <Card.Body>
            <Card.Text>
            <div id="principles_text">
                <p>Graduated from <ins>Ecole Nationale Supérieure des Télécommunications (ENST)</ins>, Gérard Dupin has 30 years of experience in the telecommunication field. He has held various executive positions within France Telecom (FT) :
                <ul>
                  <li>first in operational departments in the area of switching and subsequently access and transmission networks,</li>
                  <li>within FT R&D where he was responsible for the design of the FT synchronisation network, and for the acceptance and network integration of signal processing transmission equipment, and related quality issues within the domestic and international networks,</li>
                  <li>in the Submarine Cable Department where he was responsible for the Department of Engineering : he has been involved for almost ten years in the engineering, design and implementation of the various FT submarine projects (Channel, Mediterranean Sea, Atlantic & Pacific Oceans). He has also an extensive experience in the area of route design and cable protection, and has been Chairman of the International Cable Protection Committee (ICPC).</li>
                </ul></p>
                <p>Subsequently he joined Global Crossing as General Manager for France where for over three years he was in charge of setting up the local organisation and roll out of the network. Due to his thorough experience in the area of submarine systems, he has been responsible for the implementation of the submarine sections of the PEC network.</p>
                <p>Since completion of this network, he has formed his own company and is providing consultancy services in the area of submarine systems, and strategy and business development for various telecom companies. In addition, Gerard is actively involved in the commercialisation of technology for several companies.</p>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        </FadeIn>
      </Col> 
    </Row>
    </Container>
   </>
  );
}

export default Principles;
