// Modules //
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Components //
import Welcome from './components/Welcome/Welcome';
import MyNavbar from './components/MyNavbar/MyNavbar';
import AboutUs from './components/AboutUs/AboutUs';
import MissionStatement from './components/MissionStatement/MissionStatement';
import SubseaNetworks from './components/SubseaNetworks/SubseaNetworks';
import StrategyAndBusinessDevelopment from './components/StrategyAndBusinessDevelopment/StrategyAndBusinessDevelopment';
import Art from './components/Art/Art';
import Principles from './components/Principles/Principles';
import Contacts from './components/Contacts/Contacts';
import My404 from './components/My404/My404';


// Stylesheets // 
import './App.css';

function App() {
  return (
    <>
      <MyNavbar />
      <Router>
        <Switch>
          <Route exact path="/" exact component={()=>{return <Welcome />}} />
          <Route exact path="/about_us" exact component={()=>{return <AboutUs />}} />
          <Route exact path="/mission_statement" exact component={()=>{return <MissionStatement />}} />
          <Route exact path="/subsea_networks" exact component={()=>{return <SubseaNetworks />}} />
          <Route exact path="/strategy_and_business_development" exact component={()=>{return <StrategyAndBusinessDevelopment />}} />
          <Route exact path="/art" exact component={()=>{return <Art />}} />
          <Route exact path="/principles" exact component={()=>{return <Principles />}} />
          <Route exact path="/contacts" exact component={()=>{return <Contacts />}} />
          <Route component={My404} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
