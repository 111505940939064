// Modules //
import React from 'react';
import {Container, Col, Row, Card} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './Art.css';
// Assets //
import Submarine_Network05 from "../../assets/submarine_network_05.jpg"

function Art() {
  return (
   <>
    <Container id="art_page">
    <Row>
      <Col>
      <br />
      <br />
      <br />
      <br />
      <br />
        <FadeIn delay="150">
          <Card>
            <Card.Img variant="top"  src={Submarine_Network05} />
          </Card>
          <blockquote className="Miguel_Chevalier_Quote">by <b><i><a target="_blank" rel="noopener noreferrer" href="https://www.miguel-chevalier.com/">Miguel Chevalier</a></i></b></blockquote>
        </FadeIn>
      </Col>
      <Col>
        <FadeIn delay="450">
        <Card bg="transparent" text="less-light">
          <Card.Body>
            <Card.Text>
              <div id="art_text">
              <p>With close relationships with several reknowned contemporary artists, Taho! will help you to differentiate your communication, by:
              <ul>
                <li>finding an appropriate relationship with your business activity using an artistic approach</li>
                <li>using the organization’s history/background as an element of additional support</li>
                <li>accentuating the organization’s culture and expressing your sensitivity.</li>
              </ul></p>
              <p>We realize, through our contemporary artists, various communication vehicles:
              <ul>
                <li>personalization of invitations, greeting cards, phone cards, etc…</li>
                <li>realization of signed limited editions (prints)</li>
                <li>digital prints, serigraphs and engravings.</li>
              </ul></p>
              <p>We work in particular very closely with <b><i><a href="https://www.miguel-chevalier.com/">Miguel Chevalier</a></i></b>, a pioneer of digital virtual art, whose works are characterized by an exploration
                 of today’s technologies. His field of interest takes its sources from art history, the essential data of which he then reformulates with the
                 help of computer tools. His topics refer to his observation of flows and networks which organize our contemporary civilizations. 
                 The images which he delivers to us perpetually question our relation to the world.</p>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        </FadeIn>
      </Col> 
      </Row>
    </Container>
   </>
  );
}

export default Art;
