// Modules //
import React from 'react';
import {Container, Col, Row, Card} from "react-bootstrap";
import FadeIn from 'react-fade-in';
// Stylesheets // 
import './MissionStatement.css';
// Assets //
import Submarine_Network03 from "../../assets/submarine_network_01&03.jpg"

function MissionStatement() {
  return (
   <>
    <br />
    <br />
    <Container id="mission_statement_page">
    <Row>
      <Col>
        <FadeIn delay="150">
        <Card bg="transparent" text="less-light">
          <Card.Body>
            <Card.Text>
              <div id="mission_statement_text">
              <p>Our <ins>GOAL</ins> is to help our customers to efficiently and successfully develop their businesses so that they reach their full potential, by:</p>
              <ul>
                <li>making the best choices (strategy, partners, suppliers, ...) for new entrants or to develop their business in order to have a significant competitive advantage</li><br />
                <li>helping them to solve their issues, taking into account all aspects of their business</li><br />
                <li>leading targeted actions which will bring appropriate results in terms of strategy, organisation and information systems through a permanent willing to make our customers succeed by helping them to create and maintain a competitive advantage. We have the determination to always keep in mind the business and the strategic objectives of our customers, independently of the mission which has been given to us.</li>
              </ul>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        </FadeIn>
      </Col>
      <Col>
      <br />
      <br />
      <br />
        <FadeIn delay="450">
          <Card>
            <Card.Img variant="top" height="489" width="459" src={Submarine_Network03} />
          </Card>
          <blockquote className="Miguel_Chevalier_Quote">by <b><i><a target="_blank" rel="noopener noreferrer" href="https://www.miguel-chevalier.com/">Miguel Chevalier</a></i></b></blockquote>
        </FadeIn>
      </Col>
      </Row>
    </Container>
   </>
  );
}

export default MissionStatement;
